import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Link } from "gatsby";
import { ByLine } from "../../layout";
import { BlogEntry, DesktopRightImage, Image } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 className="px-2 text-center">Trailguide IP and assets</h1>
    <h3 className="text-center text-lg">
  A look at the intellectual property and assets of Trailguide
    </h3>
    <br />
    <Image src="/invest/takeovertheworld.png" mdxType="Image" />
    <br />
    <h4>{`Codebase`}</h4>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  Our entire codebase is written by us and is fully owned by Trailguide. There
  are a few dependencies such as Leaflet and React, but most of code needed to
  run everything is ours.
    </div>
    <br />
    <h4>{`Trailguide.net`}</h4>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  We have built up an app for mountain bike users to find great trails to ride
  their bike on. It is today one of the best apps to use for finding great
  trails.
    </div>
    <br />
    <h4>{`Content`}</h4>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  Our database contains over 9000 trail entries from users (and quality content
  from ourselves) of which a great deal is of very high quality. All entries
  have a description and a GPS track, and a third of them have images (over 7000
  in total). The content can be used by our commercial customers to pick trails
  and tours to offer to their guests.
    </div>
    <br />
    <h4>{`Products and solutions`}</h4>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  The available products and solutions found{" "}
  <Link to="/solutions/destination" mdxType="Link">here</Link> and{" "}
  <Link to="/products" mdxType="Link">here</Link>.
    </div>
    <br />
    <h4>{`Trailguide.no`}</h4>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  Our website <Link to="https://trailguide.no" mdxType="Link">trailguide.no</Link> and all its
  content.
    </div>
    <br />
    <h4>{`Users and user community`}</h4>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  The database has nearly 10.000 registered users with names and emails. The app
  has about 35.000 unique users per month during the summer months. We have
  built a strong and dedicated user community which contributes with trails to
  the app and uses it to find and navitate trails other people have added. We
  have a Facebook account with 11.000 followers and an Instagram account with
  almost 4000 followers.
    </div>
    <br />
    <h4>{`Team`}</h4>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  Between us we have more than 30 years of experience with development of
  commercial grade software products.
    </div>
    <br />
    <h4>{`Domains`}</h4>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  The domains we have are trailguide.net, trailguide.at, trailguide.dk,
  trailguide.es, trailguide.fr, trailguide.it, trailguide.no, trailguide.pl,
  trailguide.se, trailguideapp.com, and our.guide.
    </div>
    <br />
    <h4>{`Digital maps`}</h4>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  We designed and built our own digital map tiles for the app which runs on its
  own map server. The digital map is designed specifically to work well with
  outdoor activities and be pleasing to the eye. The data is gathered from open
  sources and run through a build script we created.
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      